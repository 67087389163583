import React, { Component } from 'react'
import { Parallax } from 'react-parallax';
import './Hero.scss';
import SearchBox from 'components/SearchBox/SearchBox'

export class Hero extends Component {

    isMobile = false

    render() {
        if (typeof window != 'undefined') {
            this.isMobile = window.outerWidth <= 500 ? true : false
        }

        const {
            backgroundImage,
            title,
            copy,
            searchPlaceholder,
            searchButtonLabel,
        } = this.props

        if (!this.isMobile) {

            return (
                <div className={'hero'}>
                    <Parallax
                        blur={0}
                        bgImage={backgroundImage}
                        bgImageAlt="the cat"
                        strength={200}
                    >
                        <div className="hero-container">
                            <div className="overlay">
                                {title ?
                                    <h1>
                                        {title}
                                    </h1>
                                    : null
                                }
                                <p>
                                    {copy}
                                </p>
                                <SearchBox
                                    searchPlaceholder={searchPlaceholder}
                                    searchButtonLabel={searchButtonLabel}
                                />
                            </div>
                        </div>
                    </Parallax>
                </div>
            )
        } else {
            return (
                <div className={'hero'} style={{ backgroundImage: "url(" + backgroundImage + ") " }}>
                    <div className="hero-container">
                        <div className="overlay">
                            <h1>
                                {title}
                            </h1>
                            <p>
                                {copy}
                            </p>
                            <SearchBox
                                searchPlaceholder={searchPlaceholder}
                                searchButtonLabel={searchButtonLabel}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }

}

export default Hero;