import React, { Component } from 'react'
import './NurBlock.scss';

export class NurBlock extends Component {

    render() {

        const {
            characterIllustration,
            titleIllustration,
            nurPdf,
            buttonLabel,
            copy
        } = this.props

        return (

            <div className="nur-block">
                <ul>
                    <li>
                        <img src={characterIllustration} />
                    </li>
                    <li>
                        <img src={titleIllustration} />
                        <p>
                            {copy}
                        </p>
                        <a className="button" target="_blank" href={nurPdf}>
                            {buttonLabel}
                        </a>
                    </li>
                </ul>
            </div>
        )
    }

}

export default NurBlock;